import { extendTheme, useColorModeValue } from '@chakra-ui/react'

export function useTheme() {

  const theme = extendTheme({
    colors: {
      transparent: 'transparent',
      black: '#000',
      white: '#fff',
      dark_1: '#10002B',
      dark_2: '#240046',
      dark_3: '#3C096C',
      dark_4: '#5A189A',
      dark_5: '#7B2CBF',
      dark_6: '#9D4EDD',
      dark_7: '#C77DFF',
      dark_8: '#E0AAFF',
      dark_9: '#002846',
      dark_10: '#27001a',
      dark_11: '#002d2f',
      light_1: '#CDDAFD',
      light_2: '#DFE7FD',
      light_3: '#BEE1E6',
      light_4: '#FAD2E1',
      light_5: '#FDE2E4',
      light_6: '#FFF1E6',
      light_7: '#EAE4E9',
      light_8: '#F0EFEB',
      light_9: '#E2ECE9',
      light_10: '#93d0ff',
      light_11: '#e5a9ff',
      light_12: '#c8ffb0',
      color_1: useColorModeValue('#10002B', '#CDDAFD'),
      color_2: useColorModeValue('#240046', '#DFE7FD'),
      color_3: useColorModeValue('#3C096C', '#BEE1E6'),
      color_4: useColorModeValue('#5A189A', '#FAD2E1'),
      color_5: useColorModeValue('#7B2CBF', '#FDE2E4'),
      color_6: useColorModeValue('#9D4EDD', '#FFF1E6'),
      color_7: useColorModeValue('#C77DFF', '#EAE4E9'),
      color_8: useColorModeValue('#E0AAFF', '#F0EFEB'),
    },
    fonts: {},
    fontSizes: {},
    breakpoints: {
      sm: "680px",
      md: "1280px",
      lg: "1350px",
      xl: "1900px",
    },
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false
    }
  })

  return (theme)
}
