import React, { useState, useEffect } from 'react';
import {IconButton, Box, CloseButton, Flex, Icon, Drawer, DrawerContent, Text, useDisclosure, useColorMode, useColorModeValue} from '@chakra-ui/react';
import {FiHome, FiLinkedin, FiGithub, FiClipboard, FiMenu, FiMail, FiBookOpen, } from 'react-icons/fi';
import { Link, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import DarkModeButton from './DarkModeButton';

const LinkItems = [
  {
    "name": "Home",
    "icon": FiHome,
    "link": "/",
    "isExternal": false
  },{
    "name": "Portfolio",
    "icon": FiBookOpen,
    "link": "portfolio",
    "isExternal": false
  },{
    "name": "Get my CV",
    "icon": FiClipboard,
    "link": "https://drive.google.com/file/d/1eu4411dkVO5FqFCSzjLddaQCGLEZK618/view?usp=sharing",
    "isExternal": true
  },{
    "name": "GitHub",
    "icon": FiGithub,
    "link": "https://github.com/JacobC182",
    "isExternal": true
  },{
    "name": "LinkedIn",
    "icon": FiLinkedin,
    "link": "https://www.linkedin.com/in/jacob-currie-101458205/",
    "isExternal": true
  },{
    "name": "Contact",
    "icon": FiMail,
    "link": "contact",
    "isExternal": false
  }
];

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // eslint-disable-next-line
  const {colorMode, toggleColorMode} = useColorMode();
  const [checked, setChecked] = useState();

  useEffect(() => {
    if (colorMode === "dark") {setChecked(false)}
    else {setChecked(true)}}, [colorMode])

  return (
    <>
      <SidebarContent setChecked={setChecked} toggleColorMode={toggleColorMode} checked={checked} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="xs">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav bg={useColorMode('light_1', 'dark_1')} display={{ base: 'flex', md: 'none' }} onOpen={onOpen} setChecked={setChecked} checked={checked} toggleColorMode={toggleColorMode} />
      <Box ml={{ base: 0, md: 60 }} p="4">
      </Box>
    </>
  );
};



const MobileNav = ({ bg, toggleColorMode, setChecked, checked, onOpen, ...rest }) => {
  return (
    <Flex ml={{ base: 0, md: 60 }} px={{ base: 4, md: 24 }} height="20" alignItems="center" bg={useColorModeValue('light_1', 'dark_1')} borderBottomWidth="1px"
      borderBottomColor='gray.500' justifyContent="flex-start"
      {...rest}>
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" icon={<FiMenu />} />
      <Text fontSize={["2xl","3xl", "3xl", "3xl"]}  ml="8" fontFamily="Roboto" fontWeight="medium">
        <Link to="/" style={{color: useColorModeValue('dark_1', 'white')}}>Jacob Currie</Link>
      </Text>
      <Flex display={{ base: 'flex', md: 'none' }} paddingLeft="20px">
      <DarkModeButton checked={checked} changefcn={(e) => {setChecked(e.target.checked); toggleColorMode()}} />
      </Flex>
    </Flex>
  );
};

const SidebarContent = ({ toggleColorMode, setChecked, checked, onClose, ...rest }) => {
  return (
    <Box
    bg={useColorModeValue('light_1', 'dark_1')} borderRight="1px" borderRightColor='gray.500' w={{ base: 'full', md: 60 }} pos="fixed" h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize={["2xl","3xl", "3xl", "3xl"]} fontFamily="Roboto" fontWeight="medium">
          <Link to="/" style={{color: useColorModeValue('dark_1', 'white')}}>Jacob Currie</Link>
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Flex justifyContent="center" display={{base: "none", md: "flex"}} paddingBottom="20px">
        <DarkModeButton checked={checked} changefcn={(e) => {setChecked(e.target.checked); toggleColorMode()}} />
      </Flex>
      {LinkItems.map(CreateNavItem, onClose)}
    </Box>
  );
};

function CreateNavItem(link) {
  if (link.isExternal === true) {return (<ExternalNavItem onClick={this} key={link.name} icon={link.icon} link={link.link}>{link.name}</ExternalNavItem>)};

  if (link.isExternal === false) {return (<NavItem onClick={this} key={link.name} icon={link.icon} link={link.link}>{link.name}</NavItem>)};
};

const NavItem = ({ icon, children, link, as, onClick, ...rest }) => {
  const linktextcolor = useColorModeValue('black', 'white');
  return (
    <motion.div whileHover={{scale:1.1}} whileTap={{scale:1.1}}>
    <NavLink to={link} style={({ isActive }) => ({ color: isActive ? '#F7A072' : linktextcolor})} _focus={{ boxShadow: 'none' }}>
      <Flex align="center" p="4" mx="4" borderRadius="lg" role="group" cursor="pointer" _hover={{bg: useColorModeValue('light_3', 'dark_3'),color: linktextcolor}} onClick={onClick}>
        {icon && (<Icon mr="4" fontSize="16" _groupHover={{color: 'white'}} as={icon}/>)}
        {children}
      </Flex>
    </NavLink>
    </motion.div>
  );
};

const ExternalNavItem = ({ icon, children, link, as, onClick, ...rest }) => {
  const linktextcolor = useColorModeValue('black', 'white');
  return (
    <motion.div whileHover={{scale:1.1}} whileTap={{scale:1.1}}>
    <a  className="externalnavlink" href={link} style={{ textDecoration: 'none', color: linktextcolor }} _focus={{ boxShadow: 'none' }}>
      <Flex align="center" p="4" mx="4" borderRadius="lg" role="group" cursor="pointer" _hover={{bg: useColorModeValue('light_3', 'dark_3'),color: linktextcolor}} onClick={onClick}>
        {icon && (<Icon mr="4" fontSize="16" _groupHover={{color: 'white'}} as={icon}/>)}
        {children}
      </Flex>
    </a>
    </motion.div>
  );
};
