import { Flex, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom';

export default function Footer() {
    let year = new Date().getFullYear();
    let location = useLocation();
  return (
    <Flex className='footerbox' minH="2vH" justifyContent="center" backgroundColor={useColorModeValue(location.pathname === '/' ? 'light_12': 'light_2',
                                                                                                      location.pathname === '/' ? 'dark_11' : 'dark_2')}>
        <Text textColor={useColorModeValue('#1D2D44', 'white')}>&copy; Copyright {year}. Jacob Currie.</Text>
    </Flex>
  )
}
