import React from 'react'
import { motion } from 'framer-motion'
import { Button, useColorModeValue, Container } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

export default function ProjectsButton() {
    const navigate = useNavigate();
    
  return (
    <Container maxWidth={["450px", "450px", "550px", "750px", "800px"]} justifyContent='center' display='flex' marginTop="5px">
    <motion.div animate={{opacity:[0, 1]}} transition={{ease:"easeOut", duration:0.7, delay:0.6}}>
    <motion.div whileHover={{scale:1.2}} whileTap={{scale:1.2}} >
    <Button
     onClick={({handleClick}) => {navigate("/portfolio")}}
     backgroundColor={useColorModeValue("light_4", "dark_7")}
     fontSize="20px"
     borderRadius="20px"
     textAlign="center"
     paddingBottom="4px"
     textColor={useColorModeValue('#1D2D44', 'white')}
     _hover={{"backgroundColor":"teal.400"}}
     border={useColorModeValue("2px solid #1D2D44", "2px solid white")}
     >See my Projects</Button>
    </motion.div>
    </motion.div>
    </Container>
  )
}

