import React from 'react'
import Card from './Card';
import { motion } from 'framer-motion';
import { SimpleGrid, Heading, useColorModeValue, Container, VStack, Box } from '@chakra-ui/react';


export default function MEngSkillCards() {
    
      const meng = [
        {
          key: 12,
          title: "MATLAB",
          image: "images/matlab.png"
        },{
          key: 13,
          title: "ANSYS",
          image: "images/fluent.png"
        },{
          key: 14,
          title: "SolidWorks",
          image: "images/solidworks.png"
        },{
          key: 15,
          title: "SK Learn",
          image: "images/sklearn.png"
        },{
          key: 16,
          title: "Earth Engine",
          image: "images/earthengine.png"
        }
      ]

      function makeCards(card) {
        return (
          <motion.div animate={{opacity:[0, 0.2, 0.3, 0.6, 1], y:[-100, 0]}} transition={{ease:"easeInOut", duration:0.5, delay:card.key* 0.3 + 0.7}}>
            <Card title={card.title} image={card.image} />
          </motion.div>
        )}

  return (
    <Box width='100vw' backgroundColor={useColorModeValue('light_12', 'dark_11')}>
      <Container maxWidth={["450px", "450px", "550px", "750px", "800px"]} backgroundColor={useColorModeValue('light_12', 'dark_11')}>
      <VStack paddingBottom="10px" rowGap="15px">

        <motion.div animate={{opacity:[0, 1]}} transition={{ease:"easeIn", duration:0.7, delay:0.7}}>
        <Heading className='main-heading' backgroundColor={useColorModeValue('light_5', 'dark_6')}
        textColor={useColorModeValue('#1D2D44', 'white')} fontSize="2xl" fontWeight="350">Mechanical Engineering Proficiencies</Heading>
        </motion.div>

        <motion.div animate={{opacity:[0, 1]}} transition={{ease:"easeIn", duration:0.7, delay:0.7}}>
        <Heading className='main-heading' backgroundColor={useColorModeValue('light_5', 'dark_6')}
        textColor={useColorModeValue('#1D2D44', 'white')} fontSize="18px" fontWeight="350">Tools</Heading>
        </motion.div>

        <SimpleGrid gap="15px" columns={[2, 3]}>
          {meng.map(makeCards)}
        </SimpleGrid>
        </VStack>
        </Container>
        </Box>
  )
}
