import React from 'react'
import { motion } from 'framer-motion'
import { useColorModeValue, Image, Text, Heading, VStack, Container } from '@chakra-ui/react'

export default function Intro() {
  return (
    <>
    <Container maxWidth={["450px", "450px", "550px", "750px", "800px"]} pt="10px">
    <VStack>
        <motion.div animate={{ opacity: [0, 0.2, 0.3, 0.6, 1], y: [-200, 0] }}
        transition={{ ease: "easeOut", duration: 0.5 }}>
          <Image border={useColorModeValue("5px solid #0FA3B1", "5px solid white")}
              borderRadius="100px"
              boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
              src="images/jacob.png"
              alt='Jacob' />
        </motion.div>
        <motion.div animate={{ opacity: [0, 0.2, 0.3, 0.6, 1], y: [100, 0] }} transition={{ ease: "easeOut", duration: 0.5 }}>
              <Text bgGradient={useColorModeValue("linear(to-r, purple.300, green.400)", "linear(to-r, orange.300, teal.200)")}
                  bgClip="text"
                  fontSize="5xl" fontWeight="bold"
                  textShadow="rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px">Jacob Currie</Text>
        </motion.div><motion.div animate={{ opacity: [0, 1] }} transition={{ ease: "easeIn", duration: 0.7 }}>
            <Heading className='main-heading'
                  backgroundColor={useColorModeValue('light_3', 'dark_3')}
                  textColor={useColorModeValue('#1D2D44', 'white')}
                  fontSize="2xl"
                  fontWeight="350">Personal Profile</Heading>
        </motion.div><motion.div animate={{ opacity: [0, 1] }} transition={{ ease: "easeIn", duration: 0.7, delay: 0.2 }}>
            <Text textAlign="center" fontSize="21px" textColor={useColorModeValue('#1D2D44', 'white')}
            >Graduate level Software Engineer</Text>
            <Text maxWidth="800px"
                  textAlign="justify"
                  textColor={useColorModeValue('#1D2D44', 'white')}>
                  I am an experienced software engineer coming from a background in mechanical engineering. I graduated as top student of my year from Strathclyde University with a Masters degree in Aero-Mechanical Engineering, with specialisations in software development for engineering,
                  machine learning, CFD, aerodynamics and orbital systems.
            </Text>
        </motion.div>
        </VStack>
        </Container>
    </>
  )
}
