import React from 'react'
import { Switch, Flex, Icon, useColorModeValue } from '@chakra-ui/react'
import { FaMoon, FaSun } from 'react-icons/fa'

export default function DarkModeButton({checked, changefcn}) {

  return (
    <Flex alignContent="center" justifyContent="center" verticalAlign="middle">
        <Switch isChecked={checked} checked={checked} size="lg" onChange={changefcn} paddingTop="6px" transitionDuration="0.2s" />
        <Icon as={(checked === false  ? FaMoon : FaSun )} boxSize="40px" border="2px white solid" borderRadius="20px" padding="5px" marginLeft="16px"
            textColor={useColorModeValue( 'light_fg', 'dark_fg')}
            borderColor={useColorModeValue( 'light_fg', 'dark_fg')}
            />
    </Flex>
  )
}
