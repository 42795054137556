import React from 'react';
import LanguageSkillCards from './LanguageSkillCards';
import ProjectsButton from './ProjectsButton';
import Intro from './Intro';
import FrameworkSkillCards from './FrameworkSkillCards';
import MEngSkillCards from './MEngSkillCards';
import { useColorModeValue } from '@chakra-ui/react';

export default function Home() {
  return (
    <div id='test1'>
        <Intro />

        <ProjectsButton />

        <div className={useColorModeValue('wave-spacer wave-1-light', 'wave-spacer wave-1-dark')}></div>

        <LanguageSkillCards />

        <div className={useColorModeValue('wave-spacer wave-2-light', 'wave-spacer wave-2-dark')}></div>

        <FrameworkSkillCards />

        <div className={useColorModeValue('wave-spacer wave-3-light', 'wave-spacer wave-3-dark')}></div>

        <MEngSkillCards />

        </div>
  )
}
