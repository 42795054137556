import React from 'react'
import Card from './Card';
import { motion } from 'framer-motion';
import { SimpleGrid, Heading, useColorModeValue, Container, VStack, Box } from '@chakra-ui/react';
import '../styles/index.css'
export default function LanguageSkillCards() {

    const languages =  [
        {
          key: 1,
          title: "Python",
          image: "images/python.png"
        },{
          key: 2,
          title: "C#",
          image: "images/c.png"
        },{
          key: 3,
          title: "TypeScript",
          image: "images/TS.png"
        },{
          key: 4,
          title: "HTML",
          image: "images/HTML.png"
        },{
          key: 5,
          title: "CSS",
          image: "images/css.png"
        },{
          key: 6,
          title: "JavaScript",
          image: "images/JS.png"
        },{
          key: 7,
          title: "SQL",
          image: "images/sql.png"
        }
      ]

      function makeCards(card) {
        return (
          <motion.div animate={{opacity:[0, 0.2, 0.3, 0.6, 1], y:[-100, 0]}} transition={{ease:"easeInOut", duration:0.5, delay:card.key* 0.3 + 0.7}}>
            <Card title={card.title} image={card.image} />
          </motion.div>
        )}

  return (
    <Box width='100vw' backgroundColor={useColorModeValue('light_10', 'dark_9')}>
    <Container maxWidth={["450px", "450px", "550px", "750px", "800px"]} bg={useColorModeValue('light_10', 'dark_9')}>
      <VStack marginTop="0px" rowGap="15px">
        <motion.div animate={{opacity:[0, 1]}} transition={{ease:"easeIn", duration:0.7, delay:0.7}}>
        <Heading className='main-heading' backgroundColor={useColorModeValue('light_5', 'dark_6')}
        textColor={useColorModeValue('#1D2D44', 'white')} fontSize="2xl" fontWeight="350">Software Engineering Proficiencies</Heading>
        </motion.div>

        <motion.div animate={{opacity:[0, 1]}} transition={{ease:"easeIn", duration:0.7, delay:0.7}}>
        <Heading className='main-heading' backgroundColor={useColorModeValue('light_5', 'dark_6')}
        textColor={useColorModeValue('#1D2D44', 'white')} fontSize="18px" fontWeight="350">Languages</Heading>
        </motion.div>

        <SimpleGrid gap="15px" columns={[2, 3]}>
          {languages.map(makeCards)}
        </SimpleGrid>

        </VStack>
        </Container>
        </Box>
  )
}
