import { Container, Image, Text, VStack, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export default function Card(props) {
  return (
    <Container justifyContent="center" backgroundColor={useColorModeValue('light_6', 'dark_5')}
     maxWidth="240px" height={["190px", "280px"]} borderRadius="25px" boxShadow="rgb(38, 57, 77) 0px 20px 30px -10px;">
        <VStack marginBottom="25px">
            <Text fontSize={["20px", "3xl"]} fontWeight="400" marginTop="5px" textColor={useColorModeValue('#1D2D44', 'white')} >{props.title}</Text>
            <Image borderRadius="25px" src={props.image} maxWidth={["125px", "180px"]} />
        </VStack>
    </Container>
  )
}
