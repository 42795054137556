import { Container, Heading, Grid, Text, Image, HStack, Flex, VStack, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export default function PortfolioCard(props) {

    function makeBadges(badge) {return (<Text borderRadius="20px" padding="5px" backgroundColor={badge.color}
                                         display="flex" justifyContent="center" verticalAlign="top">
                                            <badge.icon size="25px"/>&nbsp;{badge.text}</Text>)}

    function makeLinks(link) {return (<Text backgroundColor="teal.400" padding="5px"
                                    borderRadius="20px" ><a as="a" href={link.link}  display="flex" justifyContent="center"
                                        verticalAlign="top" ><link.icon size="25px" /></a></Text>)}

    return (
        <Container maxWidth={["320px", "800px", "1200px"]} backgroundColor={useColorModeValue('light_6', 'dark_5')} borderRadius="25px" padding="20px">
            <Grid gridTemplateColumns={["1fr", "3fr 2fr"]}>
                <VStack justifyContent={["center", "left"]}>
                    <Container paddingLeft={["auto", "0px"]}>
                        <Flex justifyContent={["center", "left"]} textAlign={["center", "left"]}>
                            <Heading textColor={useColorModeValue('#1D2D44', 'white')} paddingRight="2px">
                                {props.title}
                            </Heading>
                        </Flex>
                    </Container>
                    <Text textColor={useColorModeValue('#1D2D44', 'white')} textAlign="justify" paddingRight="2px">
                        {props.text}
                    </Text>
                </VStack>
                <Flex justifyContent="center" alignItems={["end", "center"]}>
                    <VStack>
                        <HStack marginTop={["24px", "0px"]} justifyContent="center">
                            {props.badges.map(makeBadges)}
                            {props.links.map(makeLinks)}
                        </HStack >
                        <Image src={props.image}  boxSize="200px" borderRadius="25px" border="3px solid white"/>
                    </VStack>
                </Flex>
            </Grid>
        </Container>
    )
}
