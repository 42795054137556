import React from 'react'
import { Flex, useColorModeValue } from '@chakra-ui/react'
import '../styles/index.css'

export default function BaseComponent({component, lc, dc}) {
    const Component = component;
  return (
    <Flex minH="100vh" className='baseFlex' justifyContent="center" marginTop="-32px" bg={useColorModeValue(lc, dc)}>
      <Component />
    </Flex>
  )
};

BaseComponent.defaultProps = {
  lc: "light_2",
  dc: "dark_2"
}