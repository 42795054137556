import React from 'react'
import Card from './Card';
import { motion } from 'framer-motion';
import { SimpleGrid, Heading, useColorModeValue, Container, VStack, Box } from '@chakra-ui/react';


export default function FrameworkSkillCards() {
    
      const frameworks =  [
        {
          key: 6,
          title: "React",
          image: "images/react.png"
        },{
          key: 7,
          title: "Angular",
          image: "images/angular.png"
        },{
          key: 8,
          title: "Chakra UI",
          image: "images/chakra.png"
        },{
          key: 9,
          title: ".NET Core",
          image: "images/netcore.png"
        },{
          key: 10,
          title: "Git",
          image: "images/git.png"
        },{
          key: 11,
          title: "WinForms",
          image: "images/windows.png"
        }
      ]

      function makeCards(card) {
        return (
          <motion.div animate={{opacity:[0, 0.2, 0.3, 0.6, 1], y:[-100, 0]}} transition={{ease:"easeInOut", duration:0.5, delay:card.key* 0.3 + 0.7}}>
            <Card title={card.title} image={card.image} />
          </motion.div>
        )}

  return (
    <Box width='100vw' backgroundColor={useColorModeValue('light_11', 'dark_10')}>
      <Container maxWidth={["450px", "450px", "550px", "750px", "800px"]} backgroundColor={useColorModeValue('light_11', 'dark_10')}>
      <VStack marginTop="0px" rowGap="15px">
        <motion.div animate={{opacity:[0, 1]}} transition={{ease:"easeIn", duration:0.7, delay:0.7}}>
        <Heading className='main-heading' backgroundColor={useColorModeValue('light_5', 'dark_6')}
        textColor={useColorModeValue('#1D2D44', 'white')} fontSize="18px" fontWeight="350">Frameworks</Heading>
        </motion.div>

        <SimpleGrid gap="15px" columns={[2, 3]}>
          {frameworks.map(makeCards)}
        </SimpleGrid>
        </VStack>
        </Container>
        </Box>
  )
}
