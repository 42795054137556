import React from 'react'
import { Heading, useColorModeValue } from '@chakra-ui/react'

export default function NotFound() {
  return (
    <Heading bgGradient={useColorModeValue("linear(to-r, purple.300, green.400)", "linear(to-r, orange.300, teal.200)")} bgClip="text" fontSize="5xl" fontWeight="bold">
        Whoops, theres no page here...
    </Heading>
  )
}
