import { Container, Flex, Heading, VStack, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import PortfolioCard from './PortfolioCard'
import { motion } from 'framer-motion'
import { FiGithub, FiWind, FiCode, FiLayers, FiLayout, FiCpu, FiExternalLink, FiLinkedin, FiAperture, FiZap} from 'react-icons/fi'

export default function Portfolio() {

  const portfolio = [
    {
      key: 1,
      title: "MEng Industry Project",
      links: [
        {
          icon: FiGithub,
          link: "https://github.com/JacobC182/MEng-Thesis-Project---Hydrogen-Safety"
        }
      ],
      image: "images/cfdhydrogen.png",
      text: "A hydrogen leakage safe-distance-estimation model developed for Finnish company Elomatic. The solution was a machine-learning based model, that at the time of completion, outperformed any publicly available model.",
      badges: [
        {
          text: "CFD",
          icon: FiWind,
          color: "red.400"
        },{
          text: "Python",
          icon: FiCode,
          color: "yellow.500"
        },{
          text: "ML",
          icon: FiLayers,
          color: "green.500"
        }
      ]
    },{
        key: 2,
        title: "Totiv.net (Ongoing)",
        links: [
          {
            icon: FiExternalLink,
            link: "https://www.totiv.net"
          },{
            icon: FiLinkedin,
            link: "https://www.linkedin.com/company/totivofficial"
          }
        ],
        image: "images/Totiv_Cropped_2.png",
        text: "A fully featured, e-commerce platform, similar to eBay or AirBnb. Built as fullstack project with a .NET WebAPI backend, ReactJS frontend, and SQL database. Secured with Microsoft Identity.",
        badges: [
          {
            text: "C#",
          icon: FiCode,
          color: "purple.400"
          },{
            text: "React",
            icon: FiAperture,
            color: "blue.300"
          }
        ]
    },{
      key: 3,
      title: "Aerofoil Aerodynamics Solver",
      links: [
        {
          icon: FiGithub,
          link: "https://github.com/JacobC182/ME529-Coursework1-ThinAerofoilTheory"
        },{
          icon: FiGithub,
          link: "https://github.com/JacobC182/ME529-Coursework2-LiftingLineTheory"
        }
      ],
      image: "images/aerosolver.png",
      text:"2D (Thin Aerofoil Theory) and 3D (Lifting Line Theory) data and coefficient solver's for NACA 4 and 5 digit aerofoils with GUI and aerofoil plotting.",
      badges: [
        {
          text: "C#",
          icon: FiCode,
          color: "purple.400"
        },{
          text: "WinForms",
          icon: FiLayout,
          color: "blue.400"
        }
      ]
    },{
      key: 4,
      title: "Jacob Currie Portfolio Website",
      links: [],
      image: "images/portfoliohome.png",
      text:"Software and Mechanical Engineer Portfolio website. Built fully in ReactJS using Chakra UI. Framer motion and EmailJS were also utilised. The website is both Desktop and Mobile responsive.",
      badges: [
        {
          text: "React",
          icon: FiAperture,
          color: "blue.300"
        },{
          text: "Chakra",
          icon: FiZap,
          color: "teal.300"
        }
      ]
    },{
      key: 5,
      title: "MEO Orbital Simulation Study",
      links: [
        {
          icon: FiGithub,
          link: "https://github.com/JacobC182/BEng-Thesis-Project---SatOrbit"
        }
      ],
      image: "images/satorbitresult.png",
      text:"Analysis of the orbital lifetime of MEO GNSS satellites over a 200 year time span subject to major perturbing forces over a range of initial states. Results found a variety of feasible passive deorbiting disposal routes that could be used to reduce space debris.",
      badges: [
        {
          text:"MATLAB",
          icon: FiCpu,
          color: "orange.600"
        }
      ]
    },{
      key: 6,
      title: "Andrea Milani Challenge",
      links: [
        {
          icon: FiGithub,
          link: "https://github.com/JacobC182/AMChallengeStrath"
        }
      ],
      image: "images/forcemodel.png",
      text:"Space debris simulation and cataloguing system, built with the 'Heyoka' integrator package and using machine learning to predict non-measureable parameters of debris pieces.",
      badges:[
        {
        text: "Python",
        icon: FiCode,
        color: "yellow.500"
        },{
        text: "ML",
        icon: FiLayers,
        color: "green.500"
        }
      ]
    }
  ]

  function makePortfolioCard(card) {
    var directionVector = 1; //1D left/right direction vector for animation
    if (card.key % 2 === 0) {directionVector *= -1} //flip direction vector if card number is even

    return (
      <motion.div animate={{x:[directionVector*500, 0], opacity:[0, 1]}} transition={{ease:"easeOut", duration:0.6, delay:card.key*0.2}}>
        <PortfolioCard key={card.key} title={card.title} text={card.text} image={card.image} badges={card.badges} links={card.links} />
      </motion.div>
    )}

  return (
    <Flex>
      <Container maxWidth={["450px", "450px", "550px", "750px", "800px"]}>
        <VStack gap="16px" marginTop="30px">
        
        <motion.div animate={{y:[-100, 0], opacity:[0, 1]}} transition={{ease:"easeOut", duration:0.6}}>
        <Heading className='main-heading' backgroundColor={useColorModeValue('light_3', 'dark_3')}
        textColor={useColorModeValue('#1D2D44', 'white')} fontSize="2xl" fontWeight="350" border={useColorModeValue("2px solid #1D2D44", "2px solid white")}>Portfolio</Heading>
        </motion.div>
          {portfolio.map(makePortfolioCard)}
        </VStack>
      </Container>
    </Flex>
  )
}
