import React, { useState } from 'react'
import copy from 'copy-to-clipboard';
import { Text, Flex, VStack, FormLabel, Input, InputLeftElement, InputGroup,
   Textarea, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useColorModeValue, Tooltip} from '@chakra-ui/react'
import { FiUser, FiMail, FiSend, FiInfo } from 'react-icons/fi'
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';

export default function Contact() {

  var [e, sete] = useState([]);
  var errors = [];

  let [copied, setCopied] = useState(false);

  const eJSinfo = {
    servicekey: process.env.REACT_APP_EMAILJS_SERVICE_KEY,
    templatekey: process.env.REACT_APP_EMAILJS_TEMPLATE_KEY,
    apikey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,};

  const {isOpen, onOpen, onClose} = useDisclosure();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = event => {setName(event.target.value)};
  const handleEmailChange = event => {setEmail(event.target.value)};
  const handleSubjectChange = event => {setSubject(event.target.value)};
  const handleMessageChange = event => {setMessage(event.target.value)};

  function HandleMessageSend() {
    
    if (name.length > 30) {errors.push("Your name must be less than 30 characters.")}

    switch(email.length) {
      case 0:
       errors.push("You must enter your email address.");
        break;
      case !email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/):
        errors.push("You must enter a valid email address.");
        break;
      default: break;
    }
    if (name.length === 0) {errors.push("You must enter your name.")}
    if (subject.length === 0) {errors.push("You must enter a subject.")}
    if (subject.length > 30) {errors.push("Your subject must be less than 30 characters.")}
    if (message.length === 0) {errors.push("You must enter a message.")}
    if (message.length > 800) {errors.push("You must enter a message less than 800 characters.")}

    if (errors.length === 0) {
      sendmail();     
    }
    if (errors.length !== 0) {
      sete(errors);
      onOpen();
    }
  }

  function sendmail() {
    document.getElementById('sendbutton').hidden = true;
    emailjs.send(eJSinfo.servicekey, eJSinfo.templatekey, {
      name: name,
     email: email,
      subject: subject,
      message: message
      }, eJSinfo.apikey)
    .then((result) => {
      alert("Your message has been sent, you will recieve a response via email.")
      setName("");
      setEmail("");
      setSubject("");
      setMessage("")},
       (error) => {
       alert("There was a problem sending your message, please try again later.")});
  }

  function WriteMessage(message) {return (<Text fontSize="1xl" key={message}>{message}</Text>)}

  function copyClick() {
    setCopied(true);
    copy("jacob.currie.123@gmail.com");
  }

  function copyHover() {
    setCopied(false);
  }

  return (
    <>
    <VStack marginTop="35px" >
      <Tooltip label={!copied ? "Click to Copy my Email!" : "Copied!"} hasArrow placement='top' closeOnClick={false}>  
      <motion.div animate={{y:[-200, 0], opacity:[0, 1]}} transition={{ease:"easeOut", duration:0.5,}}>
      <Flex marginBottom="20px">
          <motion.div whileHover={{scale: 1.1 }} transition={{ease:"easeOut", duration:0.1,}} whileTap={{scale: 0.9}}>
          <VStack backgroundColor={useColorModeValue('light_3', 'dark_3')}
          onClick={copyClick}
          onMouseEnter={copyHover}
          borderRadius="20px"
          borderColor={useColorModeValue('#0FA3B1', 'white')}
          border="2px"
          paddingLeft="10px"
          paddingRight="10px">
              <FiMail size="80px"/>
          </VStack>
          </motion.div>
      </Flex>
      </motion.div>
      </Tooltip>
      
      <motion.div animate={{y:[600, 0], opacity:[0, 1]}} transition={{ease:"easeOut", duration:0.5,}}>
      <Flex backgroundColor={useColorModeValue('light_3', 'dark_3')} padding="20px" borderRadius="20px" borderColor={useColorModeValue('#1D2D44', 'white')} border="2px">
      <VStack>
      <Text fontSize="4xl" textColor={useColorModeValue('#1D2D44', 'white')}>Contact Me</Text>

        <FormLabel textColor={useColorModeValue('#1D2D44', 'white')}>Name</FormLabel>
        <InputGroup>
        <InputLeftElement children={<FiUser stroke={useColorModeValue('#1D2D44', 'white')}/>} />
        <Input value={name} onChange={handleNameChange} textColor={useColorModeValue('#1D2D44', 'white')} borderColor={useColorModeValue('#1D2D44', 'white')}/>
        </InputGroup>

        <FormLabel textColor={useColorModeValue('#1D2D44', 'white')}>E-mail</FormLabel>
        <InputGroup>
        <InputLeftElement children={<FiMail />} />
        <Input value={email} onChange={handleEmailChange} textColor={useColorModeValue('#1D2D44', 'white')} borderColor={useColorModeValue('#1D2D44', 'white')}/>
        </InputGroup>

        <FormLabel textColor={useColorModeValue('#1D2D44', 'white')}>Subject</FormLabel>
        <InputGroup>
        <InputLeftElement children={<FiInfo />} />
        <Input value={subject} onChange={handleSubjectChange} textColor={useColorModeValue('#1D2D44', 'white')} borderColor={useColorModeValue('#1D2D44', 'white')}/>
        </InputGroup>

        <FormLabel textColor={useColorModeValue('#1D2D44', 'white')}>Message</FormLabel>
        <Textarea maxLength="800"
          textColor={useColorModeValue('#1D2D44', 'white')}
          rows="6"
          id='messageBox'
          size="md"
          wordBreak="break-word"
          value={message}
          onChange={handleMessageChange}
          borderColor={useColorModeValue('#1D2D44', 'white')}
          isInvalid={(message.length > 800)} />

        <Text fontSize="14px" textColor={useColorModeValue('#1D2D44', 'white')}>{800 - message.length} Characters Remaining.</Text>

        <motion.div whileHover={{scale:1.2}} whileTap={{scale:1.2}}>
        <Button id='sendbutton' paddingLeft="40px" paddingRight="40px" bgColor={useColorModeValue('light_4', 'dark_7')}_hover={{"bgColor":"teal.400"}} onClick={HandleMessageSend}><FiSend /></Button>
        </motion.div>
      </VStack>
      </Flex>
      </motion.div>
      
    </VStack>

    <Modal isOpen={isOpen} onClose={onClose}><ModalOverlay />
        <ModalContent>
          <ModalHeader>Your message needs some changes.</ModalHeader>
          <ModalCloseButton />
          <ModalBody><VStack>{e.map(WriteMessage)}</VStack></ModalBody>
          <ModalFooter>
            <motion.div whileHover={{scale:1.2}}>
            <Button onClick={onClose}>Close</Button>
            </motion.div>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
  )
}
