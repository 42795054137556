import React from "react";
import { ChakraProvider } from '@chakra-ui/react'
import Navbar from './components/Navbar'
import NotFound from "./components/NotFound";
import Footer from './components/Footer'
import BaseComponent from "./components/BaseComponent"
import Home from './components/Home'
import Contact from './components/Contact'
import Portfolio from './components/Portfolio'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './styles/index.css'
import { useTheme } from './hooks/useTheme'

function App() {
  const theme = useTheme();

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<BaseComponent component={Home} lc="light_2" dc="dark_2" />} />
          <Route path="/contact" element={<BaseComponent component={Contact}/>} />
          <Route path="/portfolio" element={<BaseComponent component={Portfolio} />} />
          <Route path="*" element={<BaseComponent component={NotFound} /> } />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
